<template>
  <li :class="['userCardItem']">
    <div :class="['userCardItem__userItem', length ? 'isSubList' : '']" @click="fetchModels">
      <div class="userCardItem__container" frsc>
        <Avatar class="userCardItem__avatar" :user="user" />
        <span :class="['userCardItem__state', 'state']" :state="getState()"></span>
        <div class="userCardItem__subContainer" fccs>
          <span class="userCardItem__name">{{ user.artisticName || user.user }}</span>
          <small class="userCardItem__model" v-if="user.role === 'model'">{{ user.city }} - {{ user.office }} - {{ user.room }}</small>
        </div>
      </div>
      <span class="userCardItem__badge" v-if="length">{{ length }}</span>
    </div>
    <subList
      class="userCardItem__subList"
      title="Modelos"
      :users="models"
      :isSubList="true"
      :pagination="pagination"
      :hasHeader="false"
      :hasScroll="true"
      @getMoreUsers="getMoreModels"
      v-if="isOpen"
    />
  </li>
</template>
<script>
export default {
  name: "UserCardItem",
  components: { subList: () => import("./UserCardList.vue") },
  props: {
    user: { type: Object },
    length: { type: Number },
  },
  data: function() {
    return {
      page: 0,
      pagination: { hasMore: false, isLoading: false },
      models: [],
      isOpen: false,
    };
  },
  methods: {
    async getModels() {
      this.pagination.isLoading = true;
      const { data } = await this.$axios.get(`/users?page=${this.page}&sort=artisticName&account=model&id=${this.user._id}`);
      this.pagination.hasMore = data.hasMore;
      this.pagination.isLoading = false;
      return data.users;
    },
    async fetchModels() {
      if (this.length) {
        this.isOpen = !this.isOpen;
        if (this.isOpen) this.models = await this.getModels();
      }
    },
    async getMoreModels() {
      if (this.pagination.hasMore && !this.pagination.isLoading) {
        this.page++;
        const modelsLocal = await this.getModels();
        this.models.push(...modelsLocal);
      }
    },
    getState() {
      if (this.user.isOnline && this.user.isActive) return "online";
      if (this.user.isOnline && !this.user.isActive) return "notActive";
      if (!this.user.isOnline) return "notOnline";
    },
  },
};
</script>

<style lang="scss">
.userCardItem {
  &:hover {
    background-color: #f3f3f3;
  }
  width: 100%;
  padding: $mpadding/2;
  &__state {
    margin: 0 $mpadding/2;
  }
  &__badge {
    margin-right: 9px;
    width: 30px;
    height: 17px;
    margin-top: 8px;
    padding: 0 5px;
    text-align: center;
    font-size: 0.7rem;
    color: #fff;
    border-radius: 7px;
    background-color: #515151;
  }
  &__model {
    margin-top: -5px;
    font-size: 0.7rem;
  }
  &__subList {
    &:hover {
      background-color: white;
    }
    width: 98%;
    margin: $mpadding auto $mpadding auto;
  }
  &__userItem {
    display: flex;
    justify-content: space-between;
    width: 100%;
    &.isSubList {
      cursor: pointer;
    }
  }
  & &__avatar {
    width: $mpadding * 2;
    height: $mpadding * 2;
  }
}
</style>
